<igps-lib-spinner [showSpinner]="showSpinner"></igps-lib-spinner>
<article>
    <div class="file-activity-log__search-container m-r-8" name="file-activity-log-search-container">
        <form class="file-activity-log__search-field" name="topnav__search-field" #f="ngForm"
            (ngSubmit)="searchActivityLog()">
            <input #searchInput name="searchInput" autocomplete="off" spellcheck="false" required
                (keyup)="searchKeydown($event)" [(ngModel)]="searchText" class="file-activity-log__search-input"
                type="text" placeholder="Search Activity Log" maxlength="150" />

            <mat-icon class="search__clear" aria-label="clear icon" *ngIf="searchText"
                (click)="cleraSearchActivityLog()">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3.646 3.646a.5.5 0 0 1 .708 0L12 11.293l7.646-7.647a.5.5 0 0 1 .638-.057l.07.057a.5.5 0 0 1 0 .708L12.707 12l7.647 7.646a.5.5 0 0 1 .057.638l-.057.07a.498.498 0 0 1-.708 0L12 12.707l-7.646 7.647a.5.5 0 0 1-.638.057l-.07-.057a.5.5 0 0 1 0-.708L11.293 12 3.646 4.354a.5.5 0 0 1-.057-.638l.057-.07Z"
                        fill="currentColor"></path>
                </svg>
            </mat-icon>

            <button name="searchIcon">
                <!-- refactor -->
                <img class="search__icon__image" src="assets/svg/icon-search-light.svg" alt="search" />
            </button>
        </form>
    </div>
</article>
<article class="activity-type-row">
    <mat-form-field class="checklist-mat-form-field ig2 mat-form-field-no-animation select_size" appearance="fill">
        <mat-label>Activity Type</mat-label>
        <mat-select [(value)]="activityTypeId" placeholder="--select--" (selectionChange)="filterActivity()">
            <mat-option [value]="0">
                ALL
            </mat-option>
            <mat-option *ngFor="let type of activityTypeList" [value]="type.id">
                {{ type.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="checklist-mat-form-field ig2 mat-form-field-no-animation select_size" appearance="fill">
        <mat-label>Category Type</mat-label>
        <mat-select placeholder="--select--"></mat-select>
    </mat-form-field>
</article>
<div name="activity-card">
    <div *ngFor="let data of activityLogFilterList" name="activity-card" class="file-log-list">
        <lib-common-notes-card [content]="data.description"
            [name]="data.createdUser=='Rule Admin'?'Ignite':data.createdUser"
            [timestamp]="(data.updatedDate == null ? data.createdDate : data.updatedDate) | convertTime: 'utc' : timezone.value"
            [activitylogId]="data.activityLogId" [avatar]="data.profileImage"
            [initial]="data.createdUserInitials=='Rule Admin'?'Ignite':data.createdUserInitials"
            [showPinIcon]="false"></lib-common-notes-card>
    </div>
</div>