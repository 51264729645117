<igps-lib-spinner [showSpinner]="showSpinner"></igps-lib-spinner>
<button mat-flat-button class="ig2 button button--primary button--small button-width" (click)="openAddNotesDialog()">
  <img class="button__icon" src="assets/icons/icon-plus-light.svg" alt="plus icon" />Add Note
</button>
<article class="note__form-row">
  <mat-form-field class="checklist-mat-form-field ig2 mat-form-field-no-animation select_size" appearance="fill">
    <mat-label>Type of Note</mat-label>
    <mat-select value="0">
      <mat-option value="0">
        Task
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="checklist-mat-form-field ig2 mat-form-field-no-animation select_size" appearance="fill">
    <mat-label>Note Status</mat-label>
    <mat-select [(value)]="noteStatus" (selectionChange)="filterNotes()">
      <mat-option *ngFor="let val of keys(statusMapping)" [value]="val">
        {{val}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</article>
<div *ngFor="let data of noteFilerList" class="file-notes-list">
  <lib-common-notes-card *ngIf="!data.isIgnite&&!data.isNotValidAction" [content]="data.description"
    [name]="data.createdUser=='Rule Admin'?'Ignite':data.createdUser"
    [timestamp]="(data.updatedDate == null ? data.createdDate : data.updatedDate)| convertTime: 'utc' : timezone.value"
    taskType="Task" [task]="data.actionType?data.taskType:data.actionType" [notesId]="data.igpsNoteId"
    [notesStatus]="data.igniteStatusCd" [creator]="data.creatorUser" [editor]="data.editorUser"
    [avatar]="data.profileImage" [initial]="data.createdUserInitials=='Rule Admin'?'Ignite':data.createdUserInitials"
    [child]="false" [showExpandCollapse]="true" [charLimit]="192" source="utility" [entityTypeId]="entityType.File"
    [footer]="true" (onNotesCardEditClicked)="openEditNotesDialog(data)"
    (onNotesCardArchiveClicked)="archiveNoteCard(data,data.description,true)"
    (onNotesCardUnarchiveClicked)="archiveNoteCard(data,data.description,false)">
  </lib-common-notes-card>
  <lib-common-notes-card *ngIf="data.isNotValidAction" [name]="data.createdUser=='Rule Admin'?'Ignite':data.createdUser"
    [timestamp]="(data.updatedDate == null ? data.createdDate : data.updatedDate)| convertTime: 'utc' : timezone.value"
    taskType="Task" [task]="data.taskType" [content]="data.description"
    [initial]="data.createdUserInitials=='Rule Admin'?'Ignite':data.createdUserInitials" [avatar]="data.profileImage"
    [showPinIcon]="false" [showExpandCollapse]="true" [charLimit]="192"></lib-common-notes-card>
  <lib-common-notes-card *ngIf="(data.isIgnite && !data.isIgniteApiFailed)"
    [name]="data.createdUser=='Rule Admin'?'Ignite':data.createdUser"
    [timestamp]="(data.updatedDate == null ? data.createdDate : data.updatedDate)| convertTime: 'utc' : timezone.value"
    [content]="data.description" [initial]="data.createdUserInitials=='Rule Admin'?'Ignite':data.createdUserInitials"
    avatar="assets/svg/icon-ignite-avatar.svg" [showPinIcon]="false" [showExpandCollapse]="true" [charLimit]="192">
  </lib-common-notes-card>
</div>